<template>
  <div class="invoice" v-wechat-title="$route.meta.title">
    <navigation :msg="msg">
      <template>
        <div @click="$router.push('/applicationvoice')" class="shenvoice">
          申请发票
        </div>
      </template>
    </navigation>

    <div
      v-for="(item, index) in invoicelist"
      v-bind:key="index"
      @click="clickvoice(item)"
      class="myvoice"
    >
      <div class="one">
        <img src="../../assets/users.png" />
        <div>{{ item.invoice_title }}</div>
      </div>
      <div class="two">
        <div>开票类型</div>
        <div>{{ item.invoice_type == "1" ? "普通发票" : "专用发票" }}</div>
      </div>
      <div class="two">
        <div>开票类目</div>
        <div>
          {{ item.billing_cate == "" ? "未选择消费类目" : item.billing_cate }}
        </div>
      </div>
      <div class="three">
        <div>开票金额</div>
        <div>{{ item.money.toLocaleString() }}</div>
      </div>
      <div class="four">
        <div>申请时间</div>
        <div>{{ item.add_time }}</div>
      </div>
    </div>
    <loading @loaddom="loaddom" ref="loades"></loading>
    <div class="nomsgclasses"></div>
  </div>
</template>

<script>
import loading from "../../components/loading2/index";
export default {
  data() {
    return {
      msg: "我的发票",
      invoicelist: "",
      pageno: 0,
    };
  },
  components: {
    loading,
  },
  methods: {
    /**
     * 点击查看发票详情
     */
    clickvoice(item) {
      this.$router.push({
        path: "voicedetails",
        query: {
          id: item.id,
        },
      });
    },
    /**
     * 获取发票列表
     */
    // getvoicelist() {
    //   this.$http
    //     .post("/firm/v1/userinfo/billable_list", {
    //       reqType: "userinfo",
    //       pageno: 1,
    //       limit: 15,
    //     })
    //     .then((res) => {
    //
    //     });
    // },
    loaddom() {
      this.$refs.loades
        .loadhttpes("/firm/v1/userinfo/billable_list", {
          reqType: "userinfo",
          pageno: ++this.pageno,
          limit: 15,
        })
        .then((res) => {
          this.invoicelist = res.arraymsg;
        });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.nomsgclasses {
  height: 0.1rem;
}
.myvoice {
  background-color: #ffffff;
  margin: 0.3rem;
  padding: 0.3rem;
  border-radius: 0.3rem;
}
.shenvoice {
  z-index: 999;
  font-size: 16px;
  color: #f65a16;
  line-height: 0.4rem;
}
.one {
  display: flex;
  padding-bottom: 0.15rem;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #d9d9d9;
}
.one img {
  width: 0.46rem;
  height: 0.4rem;
  margin-right: 0.21rem;
  align-items: center;
}
.one div {
  font-size: 0.28rem;
  line-height: 0.4rem;
}
.two {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.28rem;
  line-height: 0.4rem;
  margin-top: 0.21rem;
  margin-bottom: 0.25rem;
}
.three {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.28rem;
  line-height: 0.4rem;
  margin-bottom: 0.25rem;
}
.four {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.28rem;
  line-height: 0.4rem;
}
.two > div:first-child {
  color: #616b80;
}
.three > div:first-child {
  color: #616b80;
}
.four > div:first-child {
  color: #616b80;
}

.invoice {
  position: relative;
}
#img {
  position: absolute;
  top: 2.47rem;
  left: 50%;
  transform: translateX(-50%);
  width: 3.21rem;
}
p {
  position: absolute;
  top: 6.45rem;

  left: 50%;
  transform: translateX(-50%);

  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
}
</style>
